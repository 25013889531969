<dfarm-panel
  [isClosable]="true"
  [isLeftSide]="false"
  [isMultiViewPanel]="true"
  [isVisible]="panelIsOpen"
  *ngIf="!isLoading"
  class="parent-panel"
>
  <div class="child-panel">
    <div class="title text-xl">Öntözők grafikonja</div>
    <dfarm-waterscope-mock-chart />
  </div>
</dfarm-panel>
