import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { AuthService } from 'src/app/pages/login/auth.service';
import { RoleTypesEnum } from 'src/app/shared/enums/role-type.enum';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { CoordinateViewModel, FieldDetailsViewModel } from '../../models/field.model';
import { DashboardService } from '../../services/dashboard.service';
import * as DashboardActions from '../../state/dashboard.actions';
import { DashboardPartialState } from '../../state/dashboard.reducer';
import * as DashboardQueries from '../../state/dashboard.selectors';
import { BasePanelComponent } from '../base-panel.component';
import { MethodsEnum } from './models/methods.enum';

@Component({
  selector: 'dfarm-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss'],
})
export class AddFieldComponent extends BasePanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fields: FieldDetailsViewModel[];
  @Input() gpsPermissionDenied = false;
  @Input() startGPSFieldDesignationDisabled = true;

  methodsEnum = MethodsEnum;
  // Ezzel állítom be, hogy melyik a kezdő panel
  activePanel: string;
  // Ezzel választom ki, hogy melyik módszerrel szeretném felvenni a földet
  selectedMethod: MethodsEnum;
  isDesktop: boolean;

  fieldName = new FormControl('', Validators.required);
  coordinates: CoordinateViewModel[] = [];
  isSubmitted: boolean = false;
  newFieldSize: number = 0;
  areaSizeLimit: number = 0;
  remainingHectare: number = 0;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly authService: AuthService,
    private readonly dashboardService: DashboardService,
    private readonly translateService: TranslateService,
    private readonly deviceDetectorService: DeviceDetectorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.subs.push(
      // GET Side Menu is open
      this.coreStore.pipe(select(CoreQueries.getAddFieldPanelIsOpen)).subscribe((panelIsOpen) => {
        this.panelIsOpen = panelIsOpen;
        this.isLoading = false;
      }),
      this.dashboardStore.pipe(select(DashboardQueries.getActivePanel)).subscribe((activePanel) => (this.activePanel = activePanel)),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedCoordinates))
        .subscribe((coordinates) => (this.coordinates = coordinates)),
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedCoordinatesArea)).subscribe((areaSize) => (this.newFieldSize = areaSize)),
      this.dashboardStore.pipe(select(DashboardQueries.getActivePanel)).subscribe((activePanel) => {
        // Ha átnavigálunk az első vagy az utolsó panelra, akkor törli az adatokat
        if (activePanel === 'panel1' || activePanel === 'panel4') {
          this.fieldName.setValue('');
          this.isSubmitted = false;
        }
      }),
      this.dashboardStore.pipe(select(DashboardQueries.getAreaSizeLimit)).subscribe((areaSizeLimit) => (this.areaSizeLimit = areaSizeLimit))
    );
    this.subs.push(this.authService.userRoles.subscribe((roles) => (this.userRoles = roles)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const changedProp = changes[propName];

      if (propName === 'gpsPermissionDenied' && changedProp.currentValue === true) {
        this.dashboardStore.dispatch(DashboardActions.unselectGPSFieldDesignation());
        this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel1' }));
      } else if (propName === 'fields') {
        this.fields = changedProp.currentValue;
        this.remainingHectare = this.areaSizeLimit;
        this.fields.forEach((field) => {
          this.remainingHectare = this.remainingHectare - field.areaSize;
        });
        this.remainingHectare = Math.round(this.remainingHectare * 100) / 100;
      }
    }
  }

  selectAddFieldMethod(method: MethodsEnum): void {
    this.selectedMethod = method;

    if (this.selectedMethod === MethodsEnum.map) {
      this.dashboardStore.dispatch(DashboardActions.selectDrawingOnMapFieldDesignation());
    } else if (this.selectedMethod === MethodsEnum.gps) {
      this.dashboardStore.dispatch(DashboardActions.selectGPSFieldDesignation());
    }

    this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel2' }));
  }

  clickBackToPage1(): void {
    if (this.selectedMethod === MethodsEnum.map) {
      this.dashboardStore.dispatch(DashboardActions.unselectDrawingOnMapFieldDesignation());
    } else if (this.selectedMethod === MethodsEnum.gps) {
      this.dashboardStore.dispatch(DashboardActions.unselectGPSFieldDesignation());
    }

    this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel1' }));
  }

  clickBackToPage2(): void {
    if (this.selectedMethod === MethodsEnum.map) {
      this.dashboardStore.dispatch(DashboardActions.finishDrawingOnMapFieldDesignation());
    } else if (this.selectedMethod === MethodsEnum.gps) {
      this.dashboardStore.dispatch(DashboardActions.finishGPSFieldDesignation());
    }

    this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel2' }));
  }

  saveField(): void {
    this.isSubmitted = true;
    if (this.fieldName.valid) {
      this.dashboardService.createField(this.fieldName.value, this.coordinates, this.newFieldSize).subscribe(() => {
        this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel4' }));
        this.dashboardStore.dispatch(DashboardActions.unselectDrawingOnMapFieldDesignation());
        this.dashboardStore.dispatch(DashboardActions.updateDashboardContent());
        this.fieldName.reset();
        this.isSubmitted = false;
      });
    }
  }

  resetAddFieldPanel(): void {
    this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel1' }));
    this.dashboardStore.dispatch(DashboardActions.unselectGPSFieldDesignation());
    this.dashboardStore.dispatch(DashboardActions.clearAddFieldPanelData());

    this.dashboardStore.dispatch(DashboardActions.unselectDrawingOnMapFieldDesignation());
    this.dashboardStore.dispatch(DashboardActions.selectDrawingOnMapFieldDesignation());
  }

  getTranslationTitle = (translationPath: string): string => this.translateService.instant(translationPath);

  get onlyFreeUser(): boolean {
    return this.userRoles.every((x) => x === RoleTypesEnum.Free);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
