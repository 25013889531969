import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dfarm-satellite-data-overview',
  templateUrl: './satellite-data-overview.component.html',
  styleUrls: ['./satellite-data-overview.component.scss'],
})
export class SatelliteDataOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
