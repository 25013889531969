import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IChangePasswordViewModel } from '../../models/edit-profile.model';
import { ProfileService } from '../../services/profile.service';
import { SnackbarService } from '../../services/snackbar.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'dfarm-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ButtonComponent],
})
export class ChangePasswordDialogComponent implements OnInit {
  formControls: Record<keyof IChangePasswordViewModel, FormControl> = {
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.pattern('(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[\\W_]).{8,}'),
    ]),
    newPasswordAgain: new FormControl('', [
      Validators.required,
      Validators.pattern('(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=.*[\\W_]).{8,}'),
    ]),
  };
  changePasswordFormGroup = new UntypedFormGroup(this.formControls);

  isSubmitted = false;

  constructor(
    private profileService: ProfileService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  save() {
    this.isSubmitted = true;
    if (this.changePasswordFormGroup.valid && this.passwordIsMatch) {
      this.profileService.changePassword(this.changePasswordFormGroup.value).subscribe(
        () => this.close(),
        (error) => this.snackbarService.openErrorSnackBar(error.error.errorMessage)
      );
    }
  }

  private get passwordIsMatch(): boolean {
    return (
      this.changePasswordFormGroup.controls.newPassword.value === this.changePasswordFormGroup.controls.newPasswordAgain.value &&
      this.changePasswordFormGroup.controls.newPassword.value.length >= 8
    );
  }

  close() {
    this.dialogRef.close();
  }
}
