import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { IDateRangeSelectorForm } from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import {
  AmbientLightViewModel,
  ChartNameEnum,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  CumulativeWaterAmountViewModel,
  HumidityViewModel,
  Location,
  SensorDataResolutionEnum,
  SoilMoistureViewModel,
  SoilPotentialViewModel,
  SoilTemperatureViewModel,
  TemperatureViewModel,
  WaterAmountViewModel,
} from 'src/app/pages/dashboard/models/sensor-data.model-v2';
import { SensorDataServiceV2 } from 'src/app/pages/dashboard/services/sensor-data.service-v2';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardQueries from '../../../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-sensor-viewer-details-v2',
  templateUrl: './sensor-viewer-details.component-v2.html',
  styleUrls: ['./sensor-viewer-details.component-v2.scss'],
})
export class SensorViewerDetailsComponentV2 implements OnInit {
  @ViewChild('temperatureCanvas', { static: true })
  private temperatureCanvas: ElementRef;
  temperatureChart: any;

  @ViewChild('waterAmountCanvas', { static: true })
  private waterAmountCanvas: ElementRef;
  waterAmountChart: any;

  @ViewChild('cumulativeWaterAmountCanvas', { static: true })
  private cumulativeWaterAmountCanvas: ElementRef;
  cumulativeWaterAmountChart: any;

  @ViewChild('humidityCanvas', { static: true })
  private humidityCanvas: ElementRef;
  humidityChart: any;

  @ViewChild('ambientLightCanvas', { static: true })
  private ambientLightCanvas: ElementRef;
  ambientLightChart: any;

  @ViewChild('soilTemperatureCanvas', { static: true })
  private soilTemperatureCanvas: ElementRef;
  soilTemperatureChart: any;

  @ViewChild('soilMoistureCanvas', { static: true })
  private soilMoistureCanvas: ElementRef;
  soilMoistureChart: any;

  @ViewChild('soilPotentialCanvas', { static: true })
  private soilPotentialCanvas: ElementRef;
  soilPotentialChart: any;

  subs: Subscription[] = [];
  sensorViewerPanelIsOpen = false;

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: string;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  intervalStartChangeEmitted = false;
  intervalEndChangeEmitted = false;

  @Input() hasDeviceLocation = false;

  temperatureData: TemperatureViewModel;
  soilTemperatureData: SoilTemperatureViewModel;
  waterAmountData: WaterAmountViewModel;
  cumulativeWaterAmountData: CumulativeWaterAmountViewModel;
  soilMoistureData: SoilMoistureViewModel;
  soilPotentialData: SoilPotentialViewModel;
  humidityData: HumidityViewModel;
  ambientLightData: AmbientLightViewModel;

  selectedDeviceLocation: Location & { deviceType: DeviceTypeEnum } = {
    id: undefined,
    coordinate: undefined,
    deviceType: undefined,
  };
  DeviceTypeEnum = DeviceTypeEnum;
  chartNameEnum = ChartNameEnum;
  chartResolutionMap: ChartResolutionMap[] = [];
  chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [];

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly sensorDataService: SensorDataServiceV2,
    private readonly translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const changedProp = changes[propName];

      if (propName === 'intervalStart' && changedProp.currentValue !== undefined) {
        if (!this.intervalStartChangeEmitted) {
          this.formControls.startDate.setValue(this.intervalStart.toISOString().slice(0, 10), {
            emitEvent: false,
          });
        } else {
          this.intervalStartChangeEmitted = false;
        }
      } else if (propName === 'intervalEnd' && changedProp.currentValue !== undefined) {
        if (!this.intervalEndChangeEmitted) {
          this.formControls.endDate.setValue(this.intervalEnd.toISOString().slice(0, 10), {
            emitEvent: false,
          });
        } else {
          this.intervalEndChangeEmitted = false;
        }
      }
    }
  }

  ngOnInit(): void {
    this.chartResolutionMap = this.sensorDataService.getChartResolutionMap();
    this.chartSelectedResolutionMap = this.sensorDataService.getChartSelectedResolutionMap();

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
        if (new Date(this.formControls.startDate.value).setHours(0, 0, 0, 0) !== this.intervalStart.setHours(0, 0, 0, 0)) {
          this.intervalStart = new Date(this.formControls.startDate.value);
          this.intervalStartChange.emit(this.intervalStart);
          this.intervalStartChangeEmitted = true;
        }

        if (new Date(this.formControls.endDate.value).setHours(0, 0, 0, 0) !== this.intervalEnd.setHours(0, 0, 0, 0)) {
          this.intervalEnd = new Date(this.formControls.endDate.value);
          this.intervalEndChange.emit(this.intervalEnd);
          this.intervalEndChangeEmitted = true;
        }
      })
    );

    this.subs.push(
      this.coreStore.pipe(select(CoreQueries.getSensorViewerPanelIsOpen)).subscribe((panelIsOpen) => {
        this.sensorViewerPanelIsOpen = panelIsOpen;
        if (panelIsOpen) {
          this.getData();
        } else {
          this.clearAllCharts();
        }
      })
    );

    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedDeviceLocation)).subscribe((deviceLocation) => {
        this.clearAllCharts();

        if (deviceLocation !== undefined && deviceLocation.id !== undefined) {
          this.selectedDeviceLocation = deviceLocation;
        } else {
          this.selectedDeviceLocation = {
            id: undefined,
            coordinate: undefined,
            deviceType: undefined,
          };
        }

        if (this.sensorViewerPanelIsOpen) {
          this.getData();
        }
      })
    );

    Chart.register(...registerables);

    this.temperatureChart = new Chart(this.temperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.temperature-with-degree'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.air-temperature'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.waterAmountChart = new Chart(this.waterAmountCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.water-mm'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.cumulativeWaterAmountChart = new Chart(this.cumulativeWaterAmountCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.cumulative-water-mm'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.humidityChart = new Chart(this.humidityCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.ambientLightChart = new Chart(this.ambientLightCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.ambient-light'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.ambient-light-lux'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilTemperatureChart = new Chart(this.soilTemperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilMoistureChart = new Chart(this.soilMoistureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilPotentialChart = new Chart(this.soilPotentialCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-potential-kpa'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-potential-kpa'),
            color: 'white',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });
  }

  changeChartResolution(sensorDataResolutionEnum: SensorDataResolutionEnum, chartNameEnum: ChartNameEnum): void {
    const intervalStart = new Date(this.formControls.startDate.value);
    const intervalEnd = new Date(this.formControls.endDate.value);
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    switch (chartNameEnum.valueOf()) {
      case ChartNameEnum.temperatureCanvas.valueOf():
        const temperatureChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        temperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getTemperature(this.selectedDeviceLocation.id, intervalStart, intervalEnd, temperatureChartResolution.selectedResolution)
          .subscribe((temperature) => {
            this.temperatureData = temperature;
            this.clearTemperatureChart();
            this.updateTemperatureChart(temperatureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilTemperatureCanvas.valueOf():
        const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        soilTemperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilTemperature(this.selectedDeviceLocation.id, intervalStart, intervalEnd, soilTemperatureChartResolution.selectedResolution)
          .subscribe((soilTemperature) => {
            this.soilTemperatureData = soilTemperature;
            this.clearSoilTemperatureChart();
            this.updateSoilTemperatureChart(soilTemperatureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.waterAmountCanvas.valueOf():
        const waterAmountChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        waterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getWaterAmount(this.selectedDeviceLocation.id, intervalStart, intervalEnd, waterAmountChartResolution.selectedResolution)
          .subscribe((waterAmount) => {
            this.waterAmountData = waterAmount;
            this.clearWaterAmountChart();
            this.updateWaterAmountChart(waterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.cumulativeWaterAmountCanvas.valueOf():
        const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        cumulativeWaterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getCumulativeWaterAmount(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            cumulativeWaterAmountChartResolution.selectedResolution
          )
          .subscribe((gatewayCumulativeWaterAmount) => {
            this.cumulativeWaterAmountData = gatewayCumulativeWaterAmount;
            this.clearCumulativeWaterAmountChart();
            this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilMoistureCanvas.valueOf():
        const soilMoistureChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        soilMoistureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilMoisture(this.selectedDeviceLocation.id, intervalStart, intervalEnd, soilMoistureChartResolution.selectedResolution)
          .subscribe((soilMoisture) => {
            this.soilMoistureData = soilMoisture;
            this.clearSoilMoistureChart();
            this.updateSoilMoistureChart(soilMoistureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.humidityCanvas.valueOf():
        const humidityChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        humidityChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getHumidity(this.selectedDeviceLocation.id, intervalStart, intervalEnd, humidityChartResolution.selectedResolution)
          .subscribe((humidity) => {
            this.humidityData = humidity;
            this.clearHumidityChart();
            this.updateHumidityChart(humidityChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.ambientLightCanvas.valueOf():
        const ambientLightChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        ambientLightChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getAmbientLight(this.selectedDeviceLocation.id, intervalStart, intervalEnd, ambientLightChartResolution.selectedResolution)
          .subscribe((ambientLight) => {
            this.ambientLightData = ambientLight;
            this.clearAmbientLightChart();
            this.updateAmbientLightChart(ambientLightChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilPotentialCanvas.valueOf():
        const soilPotentialChartResolution = this.chartSelectedResolutionMap.find((x) => x.chartName === chartNameEnum);
        soilPotentialChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilPotential(this.selectedDeviceLocation.id, intervalStart, intervalEnd, soilPotentialChartResolution.selectedResolution)
          .subscribe((soilPotential) => {
            this.soilPotentialData = soilPotential;
            this.clearSoilPotentialChart();
            this.updateSoilPotentialChart(soilPotentialChartResolution.selectedResolution);
          });
        break;

      default:
        break;
    }
  }

  getChartResolution(chartNameEnum: ChartNameEnum): ChartResolutionMap {
    return this.chartResolutionMap.find((x) => x.chartName === chartNameEnum);
  }

  private getData(): void {
    if (
      this.dateRangeSelectorForm.valid &&
      this.selectedDeviceLocation !== undefined &&
      this.selectedDeviceLocation.coordinate !== undefined
    ) {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);
      intervalStart.setHours(0, 0, 0, 0);
      intervalEnd.setHours(23, 59, 0, 0);

      const temperatureChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.temperatureCanvas
      ).selectedResolution;
      const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.soilTemperatureCanvas
      ).selectedResolution;
      const waterAmountChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.waterAmountCanvas
      ).selectedResolution;
      const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.cumulativeWaterAmountCanvas
      ).selectedResolution;
      const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.soilMoistureCanvas
      ).selectedResolution;
      const soilPotentialChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.soilPotentialCanvas
      ).selectedResolution;
      const humidityChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.humidityCanvas
      ).selectedResolution;
      const ambientLightChartResolution = this.chartSelectedResolutionMap.find(
        (x) => x.chartName === ChartNameEnum.ambientLightCanvas
      ).selectedResolution;

      forkJoin([
        this.sensorDataService.getTemperature(this.selectedDeviceLocation.id, intervalStart, intervalEnd, temperatureChartResolution),
        this.sensorDataService.getSoilTemperature(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          soilTemperatureChartResolution
        ),
        this.sensorDataService.getWaterAmount(this.selectedDeviceLocation.id, intervalStart, intervalEnd, waterAmountChartResolution),
        this.sensorDataService.getCumulativeWaterAmount(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          cumulativeWaterAmountChartResolution
        ),
        this.sensorDataService.getSoilMoisture(this.selectedDeviceLocation.id, intervalStart, intervalEnd, soilMoistureChartResolution),
        this.sensorDataService.getSoilPotential(this.selectedDeviceLocation.id, intervalStart, intervalEnd, soilPotentialChartResolution),
        this.sensorDataService.getAmbientLight(this.selectedDeviceLocation.id, intervalStart, intervalEnd, ambientLightChartResolution),
        this.sensorDataService.getHumidity(this.selectedDeviceLocation.id, intervalStart, intervalEnd, humidityChartResolution),
      ]).subscribe(
        ([temperature, soilTemperature, waterAmount, cumulativeWaterAmount, soilMoisture, soilPotential, ambientLight, humidity]) => {
          this.temperatureData = temperature;
          console.log(this.temperatureData);
          this.soilTemperatureData = soilTemperature;
          this.waterAmountData = waterAmount;
          this.cumulativeWaterAmountData = cumulativeWaterAmount;
          this.soilMoistureData = soilMoisture;
          this.soilPotentialData = soilPotential;
          this.ambientLightData = ambientLight;
          this.humidityData = humidity;

          this.updateTemperatureChart(temperatureChartResolution);
          this.updateSoilTemperatureChart(soilTemperatureChartResolution);
          this.updateWaterAmountChart(waterAmountChartResolution);
          this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution);
          this.updateSoilMoistureChart(soilMoistureChartResolution);
          this.updateSoilPotentialChart(soilPotentialChartResolution);
          this.updateAmbientLightChart(ambientLightChartResolution);
          this.updateHumidityChart(humidityChartResolution);
        }
      );
    }
  }

  private clearTemperatureChart(): void {
    if (this.temperatureChart === undefined) {
      return;
    }

    this.temperatureChart.data.labels = [];
    this.temperatureChart.data.datasets = [];

    this.temperatureChart.update();
  }

  private updateTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.temperatureData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.temperatureData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.temperatureData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.temperatureData.daily.minimum.forEach((minimum) => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.temperatureData.daily.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.temperatureData.daily.maximum.forEach((maximum) => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach((label) => {
      this.temperatureChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.temperatureChart.data.datasets.push(dataset);
    });

    this.temperatureChart.update();
  }

  private clearWaterAmountChart(): void {
    if (this.waterAmountChart === undefined) {
      return;
    }

    this.waterAmountChart.data.labels = [];
    this.waterAmountChart.data.datasets = [];

    this.waterAmountChart.update();
  }

  private updateWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.waterAmountData.raw.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.raw.values.forEach((value) => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.waterAmountData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.hourly.sum.forEach((sum) => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.waterAmountData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.daily.sum.forEach((sum) => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach((label) => {
      this.waterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.waterAmountChart.data.datasets.push(dataset);
    });

    this.waterAmountChart.update();
  }

  private clearCumulativeWaterAmountChart(): void {
    if (this.cumulativeWaterAmountChart === undefined) {
      return;
    }

    this.cumulativeWaterAmountChart.data.labels = [];
    this.cumulativeWaterAmountChart.data.datasets = [];

    this.cumulativeWaterAmountChart.update();
  }

  private updateCumulativeWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.cumulativeWaterAmountData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.cumulativeWaterAmountData.hourly.sum.forEach((sum) => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.cumulativeWaterAmountData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.cumulativeWaterAmountData.daily.sum.forEach((sum) => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach((label) => {
      this.cumulativeWaterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.cumulativeWaterAmountChart.data.datasets.push(dataset);
    });

    this.cumulativeWaterAmountChart.update();
  }

  private clearHumidityChart(): void {
    if (this.humidityChart === undefined) {
      return;
    }

    this.humidityChart.data.labels = [];
    this.humidityChart.data.datasets = [];

    this.humidityChart.update();
  }

  private updateHumidityChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.humidityData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.humidityData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.humidityData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.humidityData.daily.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach((label) => {
      this.humidityChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.humidityChart.data.datasets.push(dataset);
    });

    this.humidityChart.update();
  }

  private clearAmbientLightChart(): void {
    if (this.ambientLightChart === undefined) {
      return;
    }

    this.ambientLightChart.data.labels = [];
    this.ambientLightChart.data.datasets = [];

    this.ambientLightChart.update();
  }

  private updateAmbientLightChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.ambientLightData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.ambientLightData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach((label) => {
      this.ambientLightChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.ambientLightChart.data.datasets.push(dataset);
    });

    this.ambientLightChart.update();
  }

  private clearSoilTemperatureChart(): void {
    if (this.soilTemperatureChart === undefined) {
      return;
    }

    this.soilTemperatureChart.data.labels = [];
    this.soilTemperatureChart.data.datasets = [];

    this.soilTemperatureChart.update();
  }

  private updateSoilTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilTemperatureData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.6)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.hourly.level1Average.forEach((average) => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.4)',
        backgroundColor: 'rgba(255, 128, 0, 0.4)',
      };
      this.soilTemperatureData.hourly.level2Average.forEach((average) => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.2)',
        backgroundColor: 'rgba(255, 128, 0, 0.2)',
      };
      this.soilTemperatureData.hourly.level3Average.forEach((average) => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilTemperatureData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      // const minimumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
      //   data: [],
      //   borderColor: 'rgba(0, 128, 255, 0.8)',
      //   backgroundColor: 'rgba(0, 128, 255, 0.6)',
      // };
      // this.soilTemperatureData.daily.level1Minimum.forEach((minimum) => {
      //   minimumDataset.data.push(minimum);
      // });
      // newDatasets.push(minimumDataset);

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.6)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.daily.level1Average.forEach((average) => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.4)',
        backgroundColor: 'rgba(255, 128, 0, 0.4)',
      };
      this.soilTemperatureData.daily.level2Average.forEach((average) => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.2)',
        backgroundColor: 'rgba(255, 128, 0, 0.2)',
      };
      this.soilTemperatureData.daily.level3Average.forEach((average) => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);

      // const maximumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
      //   data: [],
      //   borderColor: 'rgba(255, 0, 0, 0.8)',
      //   backgroundColor: 'rgba(255, 0, 0, 0.6)',
      // };
      // this.soilTemperatureData.daily.level1Maximum.forEach((maximum) => {
      //   maximumDataset.data.push(maximum);
      // });
      // newDatasets.push(maximumDataset);
    }

    newLabels.forEach((label) => {
      this.soilTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.soilTemperatureChart.data.datasets.push(dataset);
    });

    this.soilTemperatureChart.update();
  }

  private clearSoilMoistureChart(): void {
    if (this.soilMoistureChart === undefined) {
      return;
    }

    this.soilMoistureChart.data.labels = [];
    this.soilMoistureChart.data.datasets = [];

    this.soilMoistureChart.update();
  }

  private updateSoilMoistureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.soilMoistureData.raw.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const level1ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.raw.level1Values.forEach((value) => {
        level1ValuesDataset.data.push(value);
      });
      newDatasets.push(level1ValuesDataset);

      const level2ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.raw.level2Values.forEach((value) => {
        level2ValuesDataset.data.push(value);
      });
      newDatasets.push(level2ValuesDataset);

      const level3ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.raw.level3Values.forEach((value) => {
        level3ValuesDataset.data.push(value);
      });
      newDatasets.push(level3ValuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilMoistureData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.hourly.level1Average.forEach((average) => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.hourly.level2Average.forEach((average) => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.hourly.level3Average.forEach((average) => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilMoistureData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      // const minimumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
      //   data: [],
      //   borderColor: 'rgba(0, 128, 255, 0.8)',
      //   backgroundColor: 'rgba(0, 128, 255, 0.6)',
      // };
      // this.soilMoistureData.daily.level1Minimum.forEach((minimum) => {
      //   minimumDataset.data.push(minimum);
      // });
      // newDatasets.push(minimumDataset);

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.daily.level1Average.forEach((average) => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.daily.level2Average.forEach((average) => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.daily.level3Average.forEach((average) => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);

      // const maximumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
      //   data: [],
      //   borderColor: 'rgba(255, 0, 0, 0.8)',
      //   backgroundColor: 'rgba(255, 0, 0, 0.6)',
      // };
      // this.soilMoistureData.daily.level1Maximum.forEach((maximum) => {
      //   maximumDataset.data.push(maximum);
      // });
      // newDatasets.push(maximumDataset);
    }

    newLabels.forEach((label) => {
      this.soilMoistureChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.soilMoistureChart.data.datasets.push(dataset);
    });

    this.soilMoistureChart.update();
  }

  private clearSoilPotentialChart(): void {
    if (this.soilPotentialChart === undefined) {
      return;
    }

    this.soilPotentialChart.data.labels = [];
    this.soilPotentialChart.data.datasets = [];

    this.soilPotentialChart.update();
  }

  private updateSoilPotentialChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilPotentialData.hourly.time.forEach((time) => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilPotentialData.hourly.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilPotentialData.daily.time.forEach((time) => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilPotentialData.daily.average.forEach((average) => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach((label) => {
      this.soilPotentialChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.soilPotentialChart.data.datasets.push(dataset);
    });

    this.soilPotentialChart.update();
  }

  private clearAllCharts(): void {
    this.clearTemperatureChart();
    this.clearWaterAmountChart();
    this.clearCumulativeWaterAmountChart();
    this.clearHumidityChart();
    this.clearAmbientLightChart();
    this.clearSoilTemperatureChart();
    this.clearSoilMoistureChart();
    this.clearSoilPotentialChart();
  }
}
