import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { TranslateModuleResolver } from './shared/utils/translate.resolver';

const routes: Routes = [
  {
    path: '',
    canLoad: [TranslateModuleResolver],
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    canActivate: [AuthGuard],
    canLoad: [TranslateModuleResolver],
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
