import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePanelComponent } from '../../base-panel.component';
import { PanelComponent } from '../../../../../shared/components/panel/panel.component';
import { select, Store } from '@ngrx/store';
import * as CoreQueries from '../../../../../core/state/core.selectors';
import { CorePartialState } from '../../../../../core/state/core.reducer';
import { DashboardPartialState } from '../../../state/dashboard.reducer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgroMonitoringDataService } from '../../../services/agromonitoring-data.service';
import { WaterscopeMockChartComponent } from '../waterscope-mock-chart/waterscope-mock-chart.component';

@Component({
  selector: 'dfarm-waterscope-charts',
  standalone: true,
  imports: [CommonModule, PanelComponent, FormsModule, ReactiveFormsModule, WaterscopeMockChartComponent],
  templateUrl: './waterscope-charts.component.html',
  styleUrl: './waterscope-charts.component.scss',
  providers: [AgroMonitoringDataService],
})
export class WaterscopeChartsComponent extends BasePanelComponent implements OnInit {
  private readonly coreStore = inject(Store<CorePartialState>);
  private readonly dashboardStore = inject(Store<DashboardPartialState>);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.coreStore.pipe(select(CoreQueries.getWaterscopeChartPanelIsOpen)).subscribe((panelIsOpen) => {
      this.panelIsOpen = panelIsOpen;
      this.isLoading = false;
    });
  }
}
