import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CoordinateViewModel, FieldDetailsViewModel, FieldsViewModel } from '../models/field.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private baseUrl = environment.baseUrl;
  private mapboxToken = environment.mapbox.accessToken;

  constructor(private http: HttpClient) {}

  getFields(): Observable<FieldsViewModel> {
    const url = this.baseUrl + 'lands';
    return this.http.get<any>(url).pipe(
      map((dto): FieldsViewModel => {
        const fieldsViewModel: FieldsViewModel = {
          lands: dto.lands.map((field) => {
            const fieldDetailsViewModel: FieldDetailsViewModel = {
              id: field.id,
              name: field.name,
              coordinates: field.coordinates.map(
                (coordinate): CoordinateViewModel => ({
                  latitude: coordinate.latitude,
                  longitude: coordinate.longitude,
                })
              ),
              areaSize: field.areaSize,
            };

            return fieldDetailsViewModel;
          }),
          areaSizeLimit: dto.areaSizeLimit,
        };

        return fieldsViewModel;
      })
    );
  }

  createField(fieldName: string, coordinates: CoordinateViewModel[], areaSize: number): Observable<number> {
    const url = this.baseUrl + 'lands';
    return this.http.post<any>(url, {
      name: fieldName,
      coordinates,
      areaSize,
    });
  }

  validateField(coordinates: CoordinateViewModel[], areaSize: number): Observable<void> {
    const url = this.baseUrl + 'lands/validate';
    return this.http.post<any>(url, {
      coordinates,
      areaSize,
    });
  }

  editField(fieldId: string, fieldName: string): Observable<void> {
    const url = this.baseUrl + `lands/${fieldId}`;
    return this.http.put<any>(url, {
      name: fieldName,
    });
  }

  deleteField(fieldId: string): Observable<void> {
    const url = this.baseUrl + `lands/${fieldId}`;
    return this.http.delete<any>(url);
  }

  getFieldLocation(field: FieldDetailsViewModel): Observable<any> {
    if (field === undefined) {
      return of('Helyzet nem található');
    }
    const longitude = field.coordinates[0].longitude;
    const latitude = field.coordinates[0].latitude;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${this.mapboxToken}`;
    return this.http.get<any>(url).pipe(map((location) => location.features[0].place_name));
  }
}
