export interface ChartResolutionMap {
  chartName: ChartNameEnum;
  resolutions: SensorDataResolutionEnum[];
}

export interface ChartSelectedResolutionMap {
  chartName: ChartNameEnum;
  selectedResolution: SensorDataResolutionEnum;
}

export enum ChartNameEnum {
  // Gateway charts
  temperatureCanvas = 'temperatureCanvas',
  waterAmountCanvas = 'waterAmountCanvas',
  cumulativeWaterAmountCanvas = 'cumulativeWaterAmountCanvas',
  humidityCanvas = 'humidityCanvas',
  pressureCanvas = 'pressureCanvas',
  windSpeedCanvas = 'windSpeedCanvas',
  uviCanvas = 'uviCanvas',
  // Gateway & Node charts
  soilTemperatureCanvas = 'soilTemperatureCanvas',
  soilMoistureCanvas = 'soilMoistureCanvas',
  soilConductivityCanvas = 'soilConductivityCanvas',
  soilTemperatureForLandCanvas = 'soilTemperatureForLandCanvas',
  soilMoistureForLandCanvas = 'soilMoistureForLandCanvas',
}

export enum SensorDataResolutionEnum {
  raw = 'raw',
  hourly = 'hourly',
  daily = 'daily',
}

export interface SensorDataLocations {
  gatewayDataLocations: Location[];
  nodeDataLocations: Location[];
}

export interface Location {
  id: string;
  coordinate: Coordinate;
}

export interface Coordinate {
  longitude: number;
  latitude: number;
}

export interface GatewayTemperatureViewModel {
  hourly: GatewayTemperatureHourlyResolutionViewModel;
  daily: GatewayTemperatureDailyResolutionViewModel;
}

export interface GatewayTemperatureHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface GatewayTemperatureDailyResolutionViewModel {
  time: Date[];
  average: number[];
  minimum: number[];
  maximum: number[];
}

export interface GatewayWaterAmountViewModel {
  raw: GatewayWaterAmountRawResolutionViewModel;
  hourly: GatewayWaterAmountHourlyResolutionViewModel;
  daily: GatewayWaterAmountDailyResolutionViewModel;
}

export interface GatewayWaterAmountRawResolutionViewModel {
  time: Date[];
  values: number[];
}

export interface GatewayWaterAmountHourlyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface GatewayWaterAmountDailyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface GatewayCumulativeWaterAmountViewModel {
  hourly: GatewayCumulativeWaterAmountHourlyResolutionViewModel;
  daily: GatewayCumulativeWaterAmountDailyResolutionViewModel;
}

export interface GatewayCumulativeWaterAmountHourlyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface GatewayCumulativeWaterAmountDailyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface GatewayHumidityViewModel {
  hourly: GatewayHumidityHourlyResolutionViewModel;
  daily: GatewayHumidityDailyResolutionViewModel;
}

export interface GatewayHumidityHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface GatewayHumidityDailyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface GatewayPressureViewModel {
  raw: GatewayPressureRawResolutionViewModel;
  hourly: GatewayPressureHourlyResolutionViewModel;
}

export interface GatewayPressureRawResolutionViewModel {
  time: Date[];
  values: number[];
}

export interface GatewayPressureHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface GatewayWindSpeedViewModel {
  raw: GatewayWindSpeedRawResolutionViewModel;
  hourly: GatewayWindSpeedHourlyResolutionViewModel;
}

export interface GatewayWindSpeedRawResolutionViewModel {
  time: Date[];
  values: number[];
}

export interface GatewayWindSpeedHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface GatewayUviViewModel {
  hourly: GatewayUviHourlyResolutionViewModel;
}

export interface GatewayUviHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface SoilTemperatureViewModel {
  hourly: SoilTemperatureHourlyResolutionViewModel;
  daily: SoilTemperatureDailyResolutionViewModel;
}

export interface SoilTemperatureHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface SoilTemperatureDailyResolutionViewModel {
  time: Date[];
  average: number[];
  minimum: number[];
  maximum: number[];
}

export interface SoilMoistureViewModel {
  raw: SoilMoistureRawResolutionViewModel;
  hourly: SoilMoistureHourlyResolutionViewModel;
  daily: SoilMoistureDailyResolutionViewModel;
}

export interface SoilMoistureRawResolutionViewModel {
  time: Date[];
  values: number[];
}

export interface SoilMoistureHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface SoilMoistureDailyResolutionViewModel {
  time: Date[];
  average: number[];
  minimum: number[];
  maximum: number[];
}

export interface ISoilTemperatureForLandViewModel {
  hourly: SoilTemperatureHourlyResolutionViewModel;
  daily: SoilTemperatureDailyResolutionViewModel;
}

export interface ISoilMoistureForLandViewModel {
  hourly: SoilMoistureHourlyResolutionViewModel;
  daily: SoilMoistureDailyResolutionViewModel;
}
