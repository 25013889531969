import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import {
  IAgroMonitoringSoilDataViewModel,
  IAgroMonitoringUviDataViewModel,
  IAgroMonitoringWeatherDataViewModel,
  IDateRangeSelectorForm,
} from '../../../models/agromonitoring-data.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FieldDetailsViewModel } from '../../../models/field.model';
import { debounceTime } from 'rxjs/operators';
import { Chart, registerables } from 'chart.js';
import { select, Store } from '@ngrx/store';
import * as DashboardQueries from '../../../state/dashboard.selectors';
import { DashboardPartialState } from '../../../state/dashboard.reducer';
import { AgroMonitoringDataService } from '../../../services/agromonitoring-data.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'dfarm-waterscope-mock-chart',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './waterscope-mock-chart.component.html',
  styleUrl: './waterscope-mock-chart.component.scss',
})
export class WaterscopeMockChartComponent implements OnInit {
  subs: Subscription[] = [];

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  selectedField: FieldDetailsViewModel;

  maxDate: string;

  @ViewChild('preassureCanvas', { static: true })
  private preassureCanvas: ElementRef;
  preassureChart: any;

  @ViewChild('waterCanvas', { static: true })
  private waterCanvas: ElementRef;
  waterChart: any;

  @ViewChild('petrolCanvas', { static: true })
  private petrolCanvas: ElementRef;
  petrolChart: any;

  @ViewChild('voltageCanvas', { static: true })
  private voltageCanvas: ElementRef;
  voltageChart: any;

  weatherData: IAgroMonitoringWeatherDataViewModel;
  soilData: IAgroMonitoringSoilDataViewModel;
  uviData: IAgroMonitoringUviDataViewModel;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly agroMonitoringDataService: AgroMonitoringDataService,
    private readonly translateService: TranslateService,
    private readonly deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.maxDate = new Date().toISOString().slice(0, 10);
    const today = new Date();
    const prev7days = new Date();
    prev7days.setDate(today.getDate() - 6);

    this.formControls.startDate.setValue(prev7days.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.formControls.endDate.setValue(today.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.subs.push(this.dateRangeSelectorForm.valueChanges.pipe(debounceTime(1000)).subscribe(() => this.getAgroMonitoringData()));

    Chart.register(...registerables);

    this.preassureChart = new Chart(this.preassureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: 'Nyomás',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Nyomás',
            color: 'white',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.waterChart = new Chart(this.waterCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: 'Átfolyó vízmennyiség',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Átfolyó vízmennyiség',
            color: 'white',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.petrolChart = new Chart(this.petrolCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: 'Üzemanyagszint',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Üzemanyagszint',
            color: 'white',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.voltageChart = new Chart(this.voltageCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: false,
              text: 'Feszültség',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Feszültség',
            color: 'white',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedField)).subscribe((selectedField) => {
        if (selectedField === undefined) {
          return;
        }

        this.selectedField = selectedField;

        this.getAgroMonitoringData();
      })
    );
  }

  private getAgroMonitoringData(): void {
    if (this.dateRangeSelectorForm.valid && typeof this.selectedField !== 'undefined') {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);

      this.agroMonitoringDataService.getAgroMonitoringWeatherData(this.selectedField.id, intervalStart, intervalEnd).subscribe((res) => {
        this.weatherData = res;
        this.updateHumidityChart();
      });
    }
  }

  updateHumidityChart() {
    const newLabels = this.weatherData.dataPoints.map((dataPoint) => dataPoint.datetime.toLocaleDateString());
    const newDatasets = [
      {
        label: this.getTranslationTitle('maximum'),
        data: this.weatherData.dataPoints.map((dataPoint) => dataPoint.humidity.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('average'),
        data: this.weatherData.dataPoints.map((dataPoint) => dataPoint.humidity.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('minimum'),
        data: this.weatherData.dataPoints.map((dataPoint) => dataPoint.humidity.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.preassureChart.data.labels = [];
    this.preassureChart.data.datasets = [];

    this.waterChart.data.labels = [];
    this.waterChart.data.datasets = [];

    this.petrolChart.data.labels = [];
    this.petrolChart.data.datasets = [];

    this.voltageChart.data.labels = [];
    this.voltageChart.data.datasets = [];

    newLabels.forEach((label) => {
      this.preassureChart.data.labels.push(label);
      this.waterChart.data.labels.push(label);
      this.petrolChart.data.labels.push(label);
      this.voltageChart.data.labels.push(label);
    });
    newDatasets.forEach((dataset) => {
      this.preassureChart.data.datasets.push(dataset);
      this.waterChart.data.datasets.push(dataset);
      this.petrolChart.data.datasets.push(dataset);
      this.voltageChart.data.datasets.push(dataset);
    });

    this.preassureChart.update();
    this.waterChart.update();
    this.petrolChart.update();
    this.voltageChart.update();
  }

  private getTranslationTitle = (translationPath: string): string =>
    this.translateService.instant(`dashboard-page.data-viewer-panel.${translationPath}`);
}
