import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CoordinateViewModel } from 'src/app/pages/dashboard/models/field.model';
import { DashboardService } from 'src/app/pages/dashboard/services/dashboard.service';
import { DashboardPartialState, DrawingOnMapFieldDesignationState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { dfarmDelay } from 'src/app/shared/utils/utils';
import * as DashboardActions from '../../../../state/dashboard.actions';
import * as DashboardQueries from '../../../../state/dashboard.selectors';
import { ValidTypeEnum } from '../../models/methods.enum';

@Component({
  selector: 'dfarm-add-field-with-map',
  templateUrl: './add-field-with-map.component.html',
  styleUrls: ['./add-field-with-map.component.scss'],
})
export class AddFieldWithMapComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  coordinates: CoordinateViewModel[] = [];
  areaSize: number;
  isValidField: ValidTypeEnum = 3;
  validTypeEnum = ValidTypeEnum;

  // Ezzel állítom be, hogy melyik a kezdő panel
  activePanel: string;

  designating = true;

  areaSizeLimit = 0;
  remainingHectare = 0;
  sumFieldSize: number = 0;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private readonly dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedCoordinates))
        .subscribe((coordinates) => (this.coordinates = coordinates)),
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedCoordinatesArea)).subscribe((areaSize) => (this.areaSize = areaSize)),
      this.dashboardStore.pipe(select(DashboardQueries.getActivePanel)).subscribe(async (activePanel) => {
        // Ha átnavigálunk az első vagy az utolsó panelra, akkor törli az adatokat
        if (activePanel === 'panel1' || activePanel === 'panel3') {
          this.isValidField = ValidTypeEnum.unknown;

          if (activePanel === 'panel1') {
            await dfarmDelay(1000);
            this.designating = true;
          }
        }
      }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getDrawingOnMapFieldDesignationState))
        .subscribe((drawingOnMapFieldDesignationState) => {
          if (drawingOnMapFieldDesignationState === DrawingOnMapFieldDesignationState.DESIGNATING) {
            this.designating = true;
          } else if (drawingOnMapFieldDesignationState === DrawingOnMapFieldDesignationState.DESIGNATED) {
            this.designating = false;
          }
        }),
      this.dashboardStore
        .pipe(take(1), select(DashboardQueries.getAreaSizeLimit))
        .subscribe((areaSizeLimit) => (this.areaSizeLimit = areaSizeLimit)),
      this.dashboardService.getFields().subscribe((res) => {
        res.lands.forEach((land) => {
          this.sumFieldSize += land.areaSize;
        });
        this.remainingHectare = this.areaSizeLimit - this.sumFieldSize;
      })
    );
  }

  finishFieldDesignation(): void {
    this.dashboardStore.dispatch(DashboardActions.finishDrawingOnMapFieldDesignation());
    this.calculateRemainingHectare();
  }

  resetFieldDesignation(): void {
    this.isValidField = ValidTypeEnum.unknown;
    this.dashboardStore.dispatch(DashboardActions.unselectGPSFieldDesignation());
    this.dashboardStore.dispatch(DashboardActions.clearAddFieldPanelData());
    this.dashboardStore.dispatch(DashboardActions.unselectDrawingOnMapFieldDesignation());
    this.dashboardStore.dispatch(DashboardActions.selectDrawingOnMapFieldDesignation());
    this.remainingHectare = this.areaSizeLimit - this.sumFieldSize;
  }

  next(): void {
    this.dashboardService.validateField(this.coordinates, this.areaSize).subscribe(
      (res) => {
        this.isValidField = ValidTypeEnum.valid;

        this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel3' }));
        this.dashboardStore.dispatch(DashboardActions.nameFieldDrawingOnMapFieldDesignation());
      },
      (err) => {
        this.isValidField = ValidTypeEnum.invalid;
        this.snackbarService.openErrorSnackBar(this.translateService.instant('dashboard-page.add-field-panel.invalid-field'));
        console.error(err);
      }
    );
  }

  private calculateRemainingHectare(): void {
    this.remainingHectare -= this.areaSize;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
