import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dfarmExpandMenu]',
})
export class ExpandMenuDirective {
  isOpen = false;
  @Input() customClassName: string;
  @HostBinding('class') get actualClass(): string {
    return this.isOpen ? this.customClassName : '';
  }
  @HostListener('click') toggleOpen($event): void {
    this.isOpen = !this.isOpen;
  }
}
