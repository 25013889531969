import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dfarmDateToLocalISO } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import {
  AmbientLightViewModel,
  ChartNameEnum,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  CumulativeWaterAmountViewModel,
  DeviceLocations,
  HumidityViewModel,
  SensorDataResolutionEnum,
  SoilMoistureViewModel,
  SoilPotentialViewModel,
  SoilTemperatureViewModel,
  TemperatureViewModel,
  WaterAmountViewModel,
} from '../models/sensor-data.model-v2';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SensorDataServiceV2 {
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getDeviceLocations(intervalStart: Date, intervalEnd: Date, fieldId: string): Observable<DeviceLocations> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/locations/${fieldId}?from=${startDate}&to=${endDate}`;

    return this.http.get<DeviceLocations>(url);
  }

  getTemperature(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/temperature/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<TemperatureViewModel>(url).pipe(
      map((dto): TemperatureViewModel => {
        const temperatureViewModel: TemperatureViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return temperatureViewModel;
      })
    );
  }

  getWaterAmount(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/water-amount/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<WaterAmountViewModel>(url).pipe(
      map((dto): WaterAmountViewModel => {
        const waterAmountViewModel: WaterAmountViewModel = {
          raw: {
            time: dto.raw.time.map((time) => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            sum: dto.hourly.sum,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            sum: dto.daily.sum,
          },
        };

        return waterAmountViewModel;
      })
    );
  }

  getCumulativeWaterAmount(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum
  ): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/cumulative-water-amount/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<CumulativeWaterAmountViewModel>(url).pipe(
      map((dto): CumulativeWaterAmountViewModel => {
        const cumulativeWaterAmountViewModel: CumulativeWaterAmountViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            sum: dto.hourly.sum,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            sum: dto.daily.sum,
          },
        };

        return cumulativeWaterAmountViewModel;
      })
    );
  }

  getHumidity(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/humidity/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<HumidityViewModel>(url).pipe(
      map((dto): HumidityViewModel => {
        const humidityViewModel: HumidityViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            average: dto.daily.average,
          },
        };

        return humidityViewModel;
      })
    );
  }

  getAmbientLight(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/ambient-light/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<AmbientLightViewModel>(url).pipe(
      map((dto): AmbientLightViewModel => {
        const ambientLightViewModel: AmbientLightViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
        };

        return ambientLightViewModel;
      })
    );
  }

  getSoilTemperature(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/soil-temperature/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilTemperatureViewModel>(url).pipe(
      map((dto): SoilTemperatureViewModel => {
        const soilTemperatureViewModel: SoilTemperatureViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            level1Average: dto.hourly.level1Average,
            level2Average: dto.hourly.level2Average,
            level3Average: dto.hourly.level3Average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            level1Average: dto.daily.level1Average,
            level1Minimum: dto.daily.level1Minimum,
            level1Maximum: dto.daily.level1Maximum,
            level2Average: dto.daily.level2Average,
            level2Minimum: dto.daily.level2Minimum,
            level2Maximum: dto.daily.level2Maximum,
            level3Average: dto.daily.level3Average,
            level3Minimum: dto.daily.level3Minimum,
            level3Maximum: dto.daily.level3Maximum,
          },
        };

        return soilTemperatureViewModel;
      })
    );
  }

  getSoilMoisture(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/soil-moisture/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilMoistureViewModel>(url).pipe(
      map((dto): SoilMoistureViewModel => {
        const soilMoistureViewModel: SoilMoistureViewModel = {
          raw: {
            time: dto.raw.time.map((time) => new Date(time)),
            level1Values: dto.raw.level1Values,
            level2Values: dto.raw.level2Values,
            level3Values: dto.raw.level3Values,
          },
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            level1Average: dto.hourly.level1Average,
            level2Average: dto.hourly.level2Average,
            level3Average: dto.hourly.level3Average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            level1Average: dto.daily.level1Average,
            level1Minimum: dto.daily.level1Minimum,
            level1Maximum: dto.daily.level1Maximum,
            level2Average: dto.daily.level2Average,
            level2Minimum: dto.daily.level2Minimum,
            level2Maximum: dto.daily.level2Maximum,
            level3Average: dto.daily.level3Average,
            level3Minimum: dto.daily.level3Minimum,
            level3Maximum: dto.daily.level3Maximum,
          },
        };

        return soilMoistureViewModel;
      })
    );
  }

  getSoilPotential(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}v2/sensor-data/soil-potential/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilPotentialViewModel>(url).pipe(
      map((dto): SoilPotentialViewModel => {
        const soilPotentialViewModel: SoilPotentialViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            average: dto.daily.average,
          },
        };

        return soilPotentialViewModel;
      })
    );
  }

  getChartResolutionMap(): ChartResolutionMap[] {
    return chartResolutionMap;
  }

  getChartSelectedResolutionMap(): ChartSelectedResolutionMap[] {
    return chartSelectedResolutionMap;
  }
}

export const chartResolutionMap: ChartResolutionMap[] = [
  {
    chartName: ChartNameEnum.temperatureCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.waterAmountCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.cumulativeWaterAmountCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.humidityCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.ambientLightCanvas,
    resolutions: [SensorDataResolutionEnum.hourly],
  },
  {
    chartName: ChartNameEnum.soilTemperatureCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilMoistureCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilPotentialCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
];

export const chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [
  {
    chartName: ChartNameEnum.temperatureCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.waterAmountCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.cumulativeWaterAmountCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.humidityCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.ambientLightCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.soilTemperatureCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.soilMoistureCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.soilPotentialCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
];
