import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FieldDetailsViewModel } from 'src/app/pages/dashboard/models/field.model';
import { DashboardService } from 'src/app/pages/dashboard/services/dashboard.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';

@Component({
  selector: 'dfarm-field-overview',
  templateUrl: './field-overview.component.html',
  styleUrls: ['./field-overview.component.scss'],
})
export class FieldOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedField: FieldDetailsViewModel;
  subs: Subscription[] = [];
  location = '';

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.subs.push(this.dashboardService.getFieldLocation(this.selectedField).subscribe((location) => (this.location = location)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.subs.push(
      this.dashboardService.getFieldLocation(changes.selectedField.currentValue).subscribe((location) => (this.location = location))
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
