import { createAction, props } from '@ngrx/store';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import { CoordinateViewModel, FieldDetailsViewModel } from '../models/field.model';
import { Location, SensorDataLocations } from '../models/sensor-data.model';
import { DeviceLocations } from '../models/sensor-data.model-v2';
import { WateringDeviceData } from '../../../shared/models/watering-device.model'; // LOAD Dashboard Data

// LOAD Dashboard Data
export const loadDashboardData = createAction('[Dashboard] Load Dashboard Data');

// Control Side Menu
export const changeAddFieldPanel = createAction('[Dashboard] Close Side Menu', props<{ activePanel: string }>());

// Add Coordinates To Store
export const addCoordinatesToStore = createAction(
  '[Dashboard] Add Coordinates To Store',
  props<{
    coordinates: CoordinateViewModel[];
  }>()
);

// Set Area size limit of user in Store
export const setAreaSizeLimit = createAction(
  '[Dashboard] Set Area size limit of user in Store',
  props<{
    areaSizeLimit: number;
  }>()
);

// Set Selected Coordinates Area
export const setSelectedCoordinatesArea = createAction('[Dashboard] Set Selected Coordinates Area', props<{ area: number }>());

// Set Selected Coordinates Area
export const clearAddFieldPanelData = createAction('[Dashboard] Clear Add Field Panel Data');

// Update Dashboard Content
export const updateDashboardContent = createAction('[Dashboard] Update Dashboard Content');

// Set Selected Field
export const setSelectedField = createAction('[Dashboard] Set Selected Field', props<{ field: FieldDetailsViewModel }>());

// Set Selected Satellite Image URL
export const setSelectedSatelliteImageURL = createAction('[Dashboard] Set Selected Image URL', props<{ satelliteImageURL: string }>());

// Set Sensor Data Locations
export const setSensorDataLocations = createAction(
  '[Dashboard] Set Sensor Data Locations',
  props<{ sensorDataLocations: SensorDataLocations }>()
);
// Select Sensor Data Location
export const selectSensorDataLocation = createAction(
  '[Dashboard] Select Sensor Data Location',
  props<{ sensorDataLocation: Location & { deviceType: DeviceTypeEnum } }>()
);

// Set Device Locations
export const setDeviceLocations = createAction('[Dashboard] Set Device Locations', props<{ deviceLocations: DeviceLocations }>());
// Select Device Location
export const selectDeviceLocation = createAction(
  '[Dashboard] Select Device Location',
  props<{ deviceLocation: Location & { deviceType: DeviceTypeEnum } }>()
);

// Set State of Field deisgnation drawing on map
export const selectDrawingOnMapFieldDesignation = createAction('[Dashboard] Select Drawing on Map Field Designation');

export const unselectDrawingOnMapFieldDesignation = createAction('[Dashboard] Unselect Drawing on Map Field Designation');

export const finishDrawingOnMapFieldDesignation = createAction('[Dashboard] Finish Drawing on Map Field Designation');

export const nameFieldDrawingOnMapFieldDesignation = createAction('[Dashboard] Name field Drawing on Map Field Designation');

export const saveFieldDrawingOnMapFieldDesignation = createAction('[Dashboard] Save field Drawing on Map Field Designation');

// Set State of Field deisgnation with GPS
export const selectGPSFieldDesignation = createAction('[Dashboard] Select GPS Field Designation');

export const unselectGPSFieldDesignation = createAction('[Dashboard] Unselect GPS Field Designation');

export const startGPSFieldDesignation = createAction('[Dashboard] Init GPS Field Designation');

export const finishGPSFieldDesignation = createAction('[Dashboard] Finish GPS Field Designation');

export const nameFieldGPSFieldDesignation = createAction('[Dashboard] Name field GPS Field Designation');

export const saveGPSFieldDesignation = createAction('[Dashboard] Save GPS Field Designation');

export const sensorDataOverviewClicked = createAction('[Dashboard] Sensor Data Overview Clicked');
export const sensorDataDetailsClicked = createAction('[Dashboard] Sensor Data Details Clicked');

// Set Selected Watering Device
export const setSelectedWateringDevice = createAction(
  '[Dashboard] Set Selected Watering Device',
  props<{ wateringDevice: WateringDeviceData }>()
);
