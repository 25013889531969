import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { AuthService } from 'src/app/pages/login/auth.service';
import * as CoreActions from '../../../../core/state/core.actions';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { BasePanelComponent } from '../base-panel.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'dfarm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends BasePanelComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  rightSidePanelIsOpen: boolean;
  isProduction: boolean = environment.production;

  constructor(
    private readonly store: Store<CorePartialState>,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    public dialog: MatDialog
  ) {
    super();
  }

  get hasFreePermission(): boolean {
    return this.userRoles.some((x) => x === this.RoleTypesEnum.Free);
  }

  get hasBasicPermission(): boolean {
    return this.userRoles.some((x) => x === this.RoleTypesEnum.Basic);
  }

  get hasSensorPermission(): boolean {
    return this.userRoles.some((x) => x === this.RoleTypesEnum.Sensor);
  }

  get hasTrialPermission(): boolean {
    return this.userRoles.some((x) => x === this.RoleTypesEnum.Trial);
  }

  ngOnInit(): void {
    // PÉLDA A FORDÍTÁSRA
    // this.translateService
    //   .get('menu.jelentesek')
    //   .pipe(take(1))
    //   .subscribe((res) => (this.asd = res));

    this.subs.push(
      // GET Side Menu is open
      this.store.pipe(select(CoreQueries.getSideMenuIsOpen)).subscribe((menuIsOpen) => {
        this.panelIsOpen = menuIsOpen;
        this.isLoading = false;
      })
    );
    this.subs.push(this.authService.userRoles.subscribe((roles) => (this.userRoles = roles)));
  }

  showAddFieldPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openAddFieldPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showFieldInspectorPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openFieldInspectorPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showServicesPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openDfarmServicesPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showDataViewerPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openDataViewerPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showSatelliteViewerPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openSatelliteViewerPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showSensorViewerPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.rightSidePanelIsOpen
      ? this.store.dispatch(CoreActions.closeSensorViewerPanel())
      : this.store.dispatch(CoreActions.openSensorViewerPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showWaterscopeItemListPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openWaterscopeItemListPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showWaterscopeTablePanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openWaterscopeTablePanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  showWaterscopeChartPanel(): void {
    this.store.dispatch(CoreActions.closeAllPanel());
    this.store.dispatch(CoreActions.openWaterscopeChartPanel());
    this.store.dispatch(CoreActions.closeSideMenu());
  }

  navigateToDfarm(): void {
    window.location.href = 'https://www.dfarm.hu';
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
