<div class="input-wrapper">
  <label class="field-name-label text-xl">
    {{ 'dashboard-page.field-inspector-panel.field-name' | translate }}
  </label>
  <input name="fieldName" type="text" [formControl]="fieldName" />
</div>
<div class="buttons">
  <button class="dark-button" (click)="cancel()">
    {{ 'dashboard-page.field-inspector-panel.cancel' | translate }}
  </button>
  <button class="light-button" (click)="save()">
    {{ 'dashboard-page.field-inspector-panel.save' | translate }}
  </button>
</div>
