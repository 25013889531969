import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SensorDataResolutionEnum } from 'src/app/pages/dashboard/models/sensor-data.model';

@Component({
  selector: 'dfarm-resolution-button-group',
  templateUrl: './resolution-button-group.component.html',
  styleUrls: ['./resolution-button-group.component.scss'],
})
export class ResolutionButtonGroupComponent implements OnInit {
  @Input() resolutions: SensorDataResolutionEnum[] = [];
  @Output() resolutionChanged = new EventEmitter<SensorDataResolutionEnum>();

  selectedResolution: SensorDataResolutionEnum;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.selectedResolution = this.resolutions[0];
  }

  changeResolution(resolution: SensorDataResolutionEnum): void {
    this.resolutionChanged.emit(resolution);
    this.selectedResolution = resolution;
  }

  getTranslationTitle = (resolution: SensorDataResolutionEnum): string =>
    this.translateService.instant('dashboard-page.sensor-viewer-panel.' + resolution.toString());
}
