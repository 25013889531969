import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModuleResolver } from 'src/app/shared/utils/translate.resolver';
import { ActivateProfileComponent } from './pages/activate-profile/activate-profile.component';
import { AszfComponent } from './pages/aszf/aszf.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';

const routes: Routes = [
  {
    canLoad: [TranslateModuleResolver],
    path: 'login',
    component: LoginComponent,
  },
  {
    canLoad: [TranslateModuleResolver],
    path: 'registration',
    component: RegisterComponent,
  },
  {
    canLoad: [TranslateModuleResolver],
    path: 'forget-password',
    component: ForgotPasswordComponent,
  },
  {
    canLoad: [TranslateModuleResolver],
    path: 'activate-profile',
    component: ActivateProfileComponent,
  },
  {
    canLoad: [TranslateModuleResolver],
    path: 'aszf',
    component: AszfComponent,
  },
  {
    canLoad: [TranslateModuleResolver],
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
