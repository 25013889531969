export interface ChartResolutionMap {
  chartName: ChartNameEnum;
  resolutions: SensorDataResolutionEnum[];
}

export interface ChartSelectedResolutionMap {
  chartName: ChartNameEnum;
  selectedResolution: SensorDataResolutionEnum;
}

export enum ChartNameEnum {
  temperatureCanvas = 'temperatureCanvas',
  waterAmountCanvas = 'waterAmountCanvas',
  cumulativeWaterAmountCanvas = 'cumulativeWaterAmountCanvas',
  humidityCanvas = 'humidityCanvas',
  ambientLightCanvas = 'ambientLightCanvas',
  soilTemperatureCanvas = 'soilTemperatureCanvas',
  soilMoistureCanvas = 'soilMoistureCanvas',
  soilPotentialCanvas = 'soilPotentialCanvas',
}

export enum SensorDataResolutionEnum {
  raw = 'raw',
  hourly = 'hourly',
  daily = 'daily',
}

export interface DeviceLocations {
  deviceLocations: Location[];
}

export interface Location {
  id: string;
  coordinate: Coordinate;
}

export interface Coordinate {
  longitude: number;
  latitude: number;
}

export interface TemperatureViewModel {
  hourly: TemperatureHourlyResolutionViewModel;
  daily: TemperatureDailyResolutionViewModel;
}

export interface TemperatureHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface TemperatureDailyResolutionViewModel {
  time: Date[];
  average: number[];
  minimum: number[];
  maximum: number[];
}

export interface WaterAmountViewModel {
  raw: WaterAmountRawResolutionViewModel;
  hourly: WaterAmountHourlyResolutionViewModel;
  daily: WaterAmountDailyResolutionViewModel;
}

export interface WaterAmountRawResolutionViewModel {
  time: Date[];
  values: number[];
}

export interface WaterAmountHourlyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface WaterAmountDailyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface CumulativeWaterAmountViewModel {
  hourly: CumulativeWaterAmountHourlyResolutionViewModel;
  daily: CumulativeWaterAmountDailyResolutionViewModel;
}

export interface CumulativeWaterAmountHourlyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface CumulativeWaterAmountDailyResolutionViewModel {
  time: Date[];
  sum: number[];
}

export interface HumidityViewModel {
  hourly: HumidityHourlyResolutionViewModel;
  daily: HumidityDailyResolutionViewModel;
}

export interface HumidityHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface HumidityDailyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface AmbientLightViewModel {
  hourly: AmbientLightHourlyResolutionViewModel;
}

export interface AmbientLightHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface SoilTemperatureViewModel {
  hourly: SoilTemperatureHourlyResolutionViewModel;
  daily: SoilTemperatureDailyResolutionViewModel;
}

export interface SoilTemperatureHourlyResolutionViewModel {
  time: Date[];
  level1Average: number[];
  level2Average: number[];
  level3Average: number[];
}

export interface SoilTemperatureDailyResolutionViewModel {
  time: Date[];
  level1Average: number[];
  level1Minimum: number[];
  level1Maximum: number[];

  level2Average: number[];
  level2Minimum: number[];
  level2Maximum: number[];

  level3Average: number[];
  level3Minimum: number[];
  level3Maximum: number[];
}

export interface SoilMoistureViewModel {
  raw: SoilMoistureRawResolutionViewModel;
  hourly: SoilMoistureHourlyResolutionViewModel;
  daily: SoilMoistureDailyResolutionViewModel;
}

export interface SoilMoistureRawResolutionViewModel {
  time: Date[];
  level1Values: number[];
  level2Values: number[];
  level3Values: number[];
}

export interface SoilMoistureHourlyResolutionViewModel {
  time: Date[];
  level1Average: number[];
  level2Average: number[];
  level3Average: number[];
}

export interface SoilMoistureDailyResolutionViewModel {
  time: Date[];
  level1Average: number[];
  level1Minimum: number[];
  level1Maximum: number[];

  level2Average: number[];
  level2Minimum: number[];
  level2Maximum: number[];

  level3Average: number[];
  level3Minimum: number[];
  level3Maximum: number[];
}

export interface SoilPotentialViewModel {
  hourly: SoilPotentialHourlyResolutionViewModel;
  daily: SoilPotentialDailyResolutionViewModel;
}

export interface SoilPotentialHourlyResolutionViewModel {
  time: Date[];
  average: number[];
}

export interface SoilPotentialDailyResolutionViewModel {
  time: Date[];
  average: number[];
}
