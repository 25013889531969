<div class="flex flex-col gap-4 items-center tabletHorizontal:h-full text-center text-white">
  <label>
    <!-- <input
      type="date"
      class="bg-transparent w-28 text-center text-sm"
      formControlName="date"
      required
    /> -->
    {{ 'dashboard-page.data-viewer-panel.prev-day' | translate }}
  </label>
  <div class="flex h-[calc(100%-1rem-20px)] w-full justify-around items-center">
    <div class="w-2/5 h-fit tabletHorizontal:h-4/5 p-1 rounded-2xl bg-sunkenBox shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.temperature' | translate }}</label>
      <div class="flex flex-col justify-evenly h-[calc(100%-2*4px-19px)]">
        <div>
          <label class="text-temperatureColor">{{ 'dashboard-page.data-viewer-panel.min' | translate }} </label>
          <label class="text-temperatureColor text-lg">{{ kpiMinimumTemperature }} °C</label>
        </div>
        <div>
          <label class="text-temperatureColor">{{ 'dashboard-page.data-viewer-panel.max' | translate }} </label>
          <label class="text-temperatureColor text-lg">{{ kpiMaximumTemperature }} °C</label>
        </div>
      </div>
    </div>
    <div class="w-2/5 h-fit tabletHorizontal:h-4/5 p-1 rounded-2xl bg-sunkenBox shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.rain' | translate }}</label>
      <div class="flex flex-col justify-evenly h-[calc(100%-2*4px-19px)]">
        <label class="text-precipitationColor text-lg"
          >{{ kpiPrecipitation }} {{ 'dashboard-page.data-viewer-panel.mm' | translate }}</label
        >
      </div>
    </div>
  </div>
</div>
