<button
  *ngFor="let resolution of resolutions; index as i"
  (click)="changeResolution(resolution)"
  [ngClass]="{
    'rounded-l-md': i === 0,
    'rounded-r-md': i === resolutions.length - 1,
    'bg-orangeButton/50': selectedResolution !== resolution,
  }"
  class="w-11 text-white text-xs p-1 bg-orangeButton"
>
  <span>
    {{ getTranslationTitle(resolution) }}
  </span>
</button>
