import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CorePartialState } from '../../../../core/state/core.reducer';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { ServiceWithFieldsListViewModel } from '../../models/field.model';

@Component({
  selector: 'dfarm-dfarm-services',
  templateUrl: './dfarm-services.component.html',
  styleUrls: ['./dfarm-services.component.scss'],
})
export class DfarmServicesComponent implements OnInit {
  subs: Subscription[] = [];
  dfarmServicesIsOpen: boolean;
  isLoading = true;

  fieldsWithServices: ServiceWithFieldsListViewModel[] = [
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
    { id: '1', fieldName: 'Búzaföld 1', isDrone: true, isSatelite: false, isSensor: true },
    { id: '2', fieldName: 'Búzaföld 2', isDrone: false, isSatelite: true, isSensor: true },
    { id: '3', fieldName: 'Búzaföld 3', isDrone: false, isSatelite: false, isSensor: true },
  ];
  displayedColumns: string[] = ['fieldName', 'isSatelite', 'isSensor', 'isDrone', 'editing'];

  constructor(private readonly coreStore: Store<CorePartialState>) {}

  ngOnInit(): void {
    this.subs.push(
      // GET Field Inspector Menu is open
      this.coreStore.pipe(select(CoreQueries.getDfarmServicesPanelIsOpen)).subscribe((panelIsOpen) => {
        this.dfarmServicesIsOpen = panelIsOpen;
        // this.dfarmServicesIsOpen = true;
        this.isLoading = false;
      })
    );
  }
}
