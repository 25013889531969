import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RoleTypesEnum } from 'src/app/shared/enums/role-type.enum';

@Component({
  selector: 'dfarm-base-panel',
  template: '',
  styles: [],
})
export class BasePanelComponent implements OnDestroy {
  userRoles: RoleTypesEnum[];
  RoleTypesEnum = RoleTypesEnum;
  subs: Subscription[] = [];
  isLoading = false;
  panelIsOpen = false;

  constructor() {}

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
