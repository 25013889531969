<dfarm-panel
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [wrapperIsBlock]="false"
  [isClosable]="true"
  [isOverflowScrollablePanel]="true"
  *ngIf="!isLoading && hasPermission"
>
  <div class="title text-xl">
    {{ 'dashboard-page.sensor-viewer-panel.sensor-data' | translate }}
  </div>
  <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
    <div class="flex-col justify-between h-full">
      <div class="flex justify-between items-center mb-4">
        <dfarm-button (click)="selectPreviousField()">
          <img class="h-7" src="../../../../../assets/quick-viewer-icons/navi-left.svg" alt="left-arrow" height="38" width="15" />
        </dfarm-button>
        <div class="text-xl text-center text-white" *ngIf="selectedField !== undefined">
          {{ selectedField.name }}
        </div>
        <dfarm-button (click)="selectNextField()">
          <img class="h-7" src="../../../../../assets/quick-viewer-icons/navi-right.svg" alt="right-arrow" height="38" width="15" />
        </dfarm-button>
      </div>

      <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab [label]="getTranslationTitle('dashboard-page.sensor-viewer-panel.overview')" *ngIf="!hasDeviceLocation">
          <div class="w-full h-full text-center text-white tabletHorizontal:max-h-[80%] desktop:max-h-[85%]">
            <dfarm-sensor-viewer-overview
              [maxDate]="maxDate"
              [intervalStart]="intervalStart"
              [intervalEnd]="intervalEnd"
              (intervalStartChange)="intervalStartChanged($event)"
              (intervalEndChange)="intervalEndChanged($event)"
              [hasSensorDataLocation]="hasSensorDataLocation"
            >
            </dfarm-sensor-viewer-overview>
          </div>
        </mat-tab>
        <mat-tab [label]="getTranslationTitle('dashboard-page.sensor-viewer-panel.details')">
          <div class="w-full h-full text-center text-white tabletHorizontal:max-h-[80%] desktop:max-h-[85%]">
            <dfarm-sensor-viewer-details
              *ngIf="!hasDeviceLocation"
              [maxDate]="maxDate"
              [intervalStart]="intervalStart"
              [intervalEnd]="intervalEnd"
              (intervalStartChange)="intervalStartChanged($event)"
              (intervalEndChange)="intervalEndChanged($event)"
              [hasSensorDataLocation]="hasSensorDataLocation"
            ></dfarm-sensor-viewer-details>
            <dfarm-sensor-viewer-details-v2
              *ngIf="hasDeviceLocation"
              [maxDate]="maxDate"
              [intervalStart]="intervalStart"
              [intervalEnd]="intervalEnd"
              (intervalStartChange)="intervalStartChanged($event)"
              (intervalEndChange)="intervalEndChanged($event)"
              [hasDeviceLocation]="hasDeviceLocation"
            ></dfarm-sensor-viewer-details-v2>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</dfarm-panel>
