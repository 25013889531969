import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'dfarm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatTooltipModule],
})
export class ButtonComponent implements OnInit, OnChanges {
  // TODO: Esetleg Attribute-ra átírni később
  @Input() disabled: boolean = false;
  @Input() type: 'submit' | 'reset' | 'button' = 'button';
  // Colors
  @Input() darkGreenButton: boolean = false;
  @Input() lightGreenButton: boolean = false;
  @Input() outlineButton: boolean = false;
  @Input() ghostButton: boolean = false;
  // Other texts
  @Input() tooltipText: string = '';
  @Input() tooltipIsDisabled: boolean = false;

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this.refreshDisabledClass(this.disabled);
    }
  }

  private refreshDisabledClass(disableButton: boolean): void {
    if (disableButton) {
      this.renderer.addClass(this.hostElement.nativeElement, 'disabled-button');
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'disabled-button');
    }
  }
}
