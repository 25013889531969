import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import * as fromCore from './state/core.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(fromCore.CORE_FEATURE_KEY, fromCore.reducer)],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
})
export class CoreModule {}
