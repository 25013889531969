import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAgroMonitoringWeatherDataViewModel } from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import { FieldDetailsViewModel } from 'src/app/pages/dashboard/models/field.model';
import { AgroMonitoringDataService } from 'src/app/pages/dashboard/services/agromonitoring-data.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import * as DashboardQueries from '../../../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-field-data-overview',
  templateUrl: './field-data-overview.component.html',
  styleUrls: ['./field-data-overview.component.scss'],
})
export class FieldDataOverviewComponent implements OnInit {
  subs: Subscription[] = [];

  selectedField: FieldDetailsViewModel;

  kpiMinimumTemperature;
  kpiMaximumTemperature;
  kpiPrecipitation;

  weatherData: IAgroMonitoringWeatherDataViewModel;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly agroMonitoringDataService: AgroMonitoringDataService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedField)).subscribe((selectedField) => {
        if (selectedField === undefined) {
          return;
        }

        this.selectedField = selectedField;

        const landId = this.selectedField.id;
        const today = new Date();
        const yesterdayStart = new Date();
        const yesterdayEnd = new Date();
        yesterdayStart.setDate(today.getDate() - 1);
        yesterdayEnd.setDate(today.getDate() - 1);
        yesterdayStart.setHours(0, 0, 0, 0);
        yesterdayEnd.setHours(23, 59, 0, 0);

        this.agroMonitoringDataService.getAgroMonitoringWeatherData(landId, yesterdayStart, yesterdayEnd).subscribe((res) => {
          this.weatherData = res;
          this.kpiMinimumTemperature = this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].temperature.minimum;
          this.kpiMaximumTemperature = this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].temperature.maximum;
          this.kpiPrecipitation = this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].precipitation.sum;
        });
      })
    );
  }
}
