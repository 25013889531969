import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleTypesEnum } from 'src/app/shared/enums/role-type.enum';
import { environment } from '../../../environments/environment';
import * as CoreActions from '../../core/state/core.actions';
import { CorePartialState } from '../../core/state/core.reducer';
import { ILocalUser, IRegisterUserViewModel } from './models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTokenTimeout: any;
  private baseUrl = environment.baseUrl;
  private userSubject: BehaviorSubject<ILocalUser>;
  public user: Observable<ILocalUser>;
  public userRoles: BehaviorSubject<RoleTypesEnum[]> = new BehaviorSubject<RoleTypesEnum[]>([]);

  constructor(
    private router: Router,
    private http: HttpClient,
    private readonly store: Store<CorePartialState>
  ) {
    this.userSubject = new BehaviorSubject<ILocalUser>(null);
    this.userRoles = new BehaviorSubject<RoleTypesEnum[]>([RoleTypesEnum.Free]);
    this.user = this.userSubject.asObservable();
  }

  loginUser(email: string, password: string): Observable<any> {
    const url = this.baseUrl + 'accounts/login';
    return this.http
      .post<any>(
        url,
        {
          email,
          password,
        },
        { withCredentials: true, observe: 'response' }
      )
      .pipe(
        map((user) => {
          this.userSubject.next(user.body);
          this.startRefreshTokenTimer();
          this.getUserRoles();
          return user.body;
        })
      );
  }

  registerUser(newUser: IRegisterUserViewModel): Observable<any> {
    const url = this.baseUrl + 'accounts/register';
    const language = localStorage.getItem('language');
    return this.http.post<any>(
      url,
      {
        ...newUser,
        language,
      },
      { withCredentials: true, observe: 'response' }
    );
  }

  resendConfirmationEmail(email: string): Observable<any> {
    const url = this.baseUrl + 'accounts/confirm-email?username=' + email;
    return this.http.get<any>(url, { withCredentials: true, observe: 'response' });
  }

  refreshToken() {
    const url = this.baseUrl + 'accounts/refresh-token';
    return this.http.post<any>(url, {}, { withCredentials: true }).pipe(
      map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        this.getUserRoles();
        return user;
      })
    );
  }

  logout() {
    const url = this.baseUrl + 'accounts/logout';
    this.http.get(url, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
    this.store.dispatch(CoreActions.closeAllPanel());
  }

  requestPassword(email: string): Observable<void> {
    const url = this.baseUrl + 'accounts/forget-password';
    return this.http.put<any>(url, { email });
  }

  setNewPassword(token: string, newPassword: string): Observable<void> {
    const url = this.baseUrl + 'accounts/forget-password';
    return this.http.post<any>(url, {
      token,
      password: newPassword,
    });
  }

  activateProfile(token: string): Observable<void> {
    const url = this.baseUrl + 'accounts/confirm-email';
    return this.http.post<any>(url, {
      token,
    });
  }

  // Helpers
  public get userValue(): ILocalUser {
    return this.userSubject.value;
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private getUserRoles() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));
    const roles = jwtToken['roles'];
    this.userRoles.next(roles);
  }
}
