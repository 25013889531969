<dfarm-menu></dfarm-menu>
<!-- <dfarm-information-panel></dfarm-information-panel> -->
<dfarm-navbar></dfarm-navbar>
<dfarm-add-field
  [fields]="fields"
  [gpsPermissionDenied]="gpsPermissionDenied"
  [startGPSFieldDesignationDisabled]="startGPSFieldDesignationDisabled"
></dfarm-add-field>
<dfarm-field-inspector (selectFieldOutput)="selectField($event)" [fields]="fields"> </dfarm-field-inspector>
<!-- <dfarm-dfarm-services></dfarm-dfarm-services> -->
<dfarm-data-viewer
  (selectNextFieldOutput)="selectNextField()"
  (selectPreviousFieldOutput)="selectPreviousField()"
  [fields]="fields"
></dfarm-data-viewer>
<dfarm-satellite-viewer (selectNextFieldOutput)="selectNextField()" (selectPreviousFieldOutput)="selectPreviousField()" [fields]="fields">
</dfarm-satellite-viewer>
<dfarm-sensor-viewer (selectNextFieldOutput)="selectNextField()" (selectPreviousFieldOutput)="selectPreviousField()"></dfarm-sensor-viewer>
<dfarm-waterscope-item-list></dfarm-waterscope-item-list>
<dfarm-waterscope-table></dfarm-waterscope-table>
<dfarm-waterscope-charts></dfarm-waterscope-charts>
<div class="match-parent" id="map"></div>
