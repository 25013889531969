<form [formGroup]="changePasswordFormGroup">
  <div class="form-title">
    {{ 'dialogs.change-password.title' | translate }}
  </div>
  <div class="input-wrapper">
    <label class="field-name-label">
      {{ 'dialogs.change-password.old-password' | translate }}
    </label>
    <input name="oldPassword" type="password" placeholder="********" [formControl]="formControls.oldPassword" />
  </div>
  <div class="input-wrapper">
    <label class="field-name-label">
      {{ 'dialogs.change-password.new-password' | translate }}
    </label>
    <input
      name="newPassword"
      type="password"
      placeholder="********"
      [ngClass]="{
        'error-input':
          (formControls.newPassword.touched && formControls.newPassword.invalid) || (isSubmitted && formControls.newPassword.invalid),
      }"
      [formControl]="formControls.newPassword"
    />
  </div>
  <div
    *ngIf="(formControls.newPassword.touched && formControls.newPassword.invalid) || (isSubmitted && formControls.newPassword.invalid)"
    class="password-requirement"
  >
    <div>{{ 'registration-page.password-requirements' | translate }}</div>
    <ul>
      <li>{{ 'registration-page.min-8-character' | translate }}</li>
      <li>{{ 'registration-page.big-and-small-characters' | translate }}</li>
      <li>{{ 'registration-page.min-number' | translate }}</li>
      <li>{{ 'registration-page.min-special-character' | translate }}</li>
    </ul>
  </div>
  <div class="input-wrapper">
    <label class="field-name-label">{{ 'dialogs.change-password.confirm-new-password' | translate }} </label>
    <input
      name="newPasswordAgain"
      type="password"
      placeholder="********"
      [ngClass]="{
        'error-input':
          (formControls.newPasswordAgain.touched && formControls.newPasswordAgain.invalid) ||
          (isSubmitted && formControls.newPasswordAgain.invalid),
      }"
      [formControl]="formControls.newPasswordAgain"
    />
  </div>
  <div
    *ngIf="
      (formControls.newPasswordAgain.touched && formControls.newPasswordAgain.value !== formControls.newPassword.value) ||
      (isSubmitted && formControls.newPasswordAgain.value !== formControls.newPassword.value)
    "
    class="password-requirement"
  >
    <div>{{ 'registration-page.not-matching-password' | translate }}</div>
  </div>
  <div class="buttons">
    <dfarm-button (click)="close()" [darkGreenButton]="true">
      {{ 'dialogs.change-password.cancel' | translate }}
    </dfarm-button>
    <dfarm-button (click)="save()" [lightGreenButton]="true">
      {{ 'dialogs.change-password.save' | translate }}
    </dfarm-button>
  </div>
</form>
