import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WateringDeviceData } from '../models/watering-device.model';

@Injectable({
  providedIn: 'root',
})
export class WateringDeviceService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getWateringDevicesByUser(userId: string): Observable<WateringDeviceData[]> {
    const url = this.baseUrl + 'admin/linear-irrigators?userId=' + userId;

    return this.http.get<WateringDeviceData[]>(url, { withCredentials: true });
  }
}
