import { Action, createReducer, on } from '@ngrx/store';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import { CoordinateViewModel, FieldDetailsViewModel } from '../models/field.model';
import { Location, SensorDataLocations } from '../models/sensor-data.model';
import * as fromDashboard from './dashboard.actions';
import { DeviceLocations } from '../models/sensor-data.model-v2';
import { WateringDeviceData } from '../../../shared/models/watering-device.model';

export const DASHBOARD_FEATURE_KEY = 'dashboard';

export enum DrawingOnMapFieldDesignationState {
  UNSELECTED,
  DESIGNATING,
  DESIGNATED,
  NAMING_FIELD,
}

export enum GPSFieldDesignationState {
  UNSELECTED,
  INIT,
  DESIGNATING,
  DESIGNATED,
  NAMING_FIELD,
}

export enum SensorDataTab {
  OVERVIEW,
  DETAILS,
}

export interface State {
  areaSizeLimit: number;
  activePanel: string; // 'panel1' | 'panel2' | 'panel3'
  selectedCoordinates: CoordinateViewModel[];
  selectedCoordinatesArea: number;
  selectedField: FieldDetailsViewModel;
  selectedSatelliteImageURL: string;
  sensorDataLocations: SensorDataLocations;
  selectedSensorDataLocation: Location & { deviceType: DeviceTypeEnum };
  drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState;
  gpsFieldDesignationState: GPSFieldDesignationState;
  selectedSensorDataTab: SensorDataTab;
  selectedWateringDevice: WateringDeviceData;
  deviceLocations: DeviceLocations;
  selectedDeviceLocation: Location & { deviceType: DeviceTypeEnum };
}

export interface DashboardPartialState {
  readonly [DASHBOARD_FEATURE_KEY]: State;
}

export const initialState: State = {
  areaSizeLimit: 0,
  activePanel: 'panel1',
  selectedCoordinates: [],
  selectedCoordinatesArea: 0,
  selectedField: undefined,
  selectedSatelliteImageURL: null,
  sensorDataLocations: undefined,
  selectedSensorDataLocation: undefined,
  drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.UNSELECTED,
  gpsFieldDesignationState: GPSFieldDesignationState.UNSELECTED,
  selectedSensorDataTab: SensorDataTab.OVERVIEW,
  deviceLocations: undefined,
  selectedDeviceLocation: undefined,
  selectedWateringDevice: undefined,
};

const dashboardReducer = createReducer(
  initialState,
  // LOAD Dashboard Data
  on(fromDashboard.loadDashboardData, (state) => ({ ...state })),

  // Set Area size limit of user in Store
  on(fromDashboard.setAreaSizeLimit, (state, payload) => ({
    ...state,
    areaSizeLimit: payload.areaSizeLimit,
  })),

  // Control Side Menu
  on(fromDashboard.changeAddFieldPanel, (state, payload) => ({
    ...state,
    activePanel: payload.activePanel,
  })),

  // Add Coordinates To Store
  on(fromDashboard.addCoordinatesToStore, (state, payload) => ({
    ...state,
    selectedCoordinates: payload.coordinates,
  })),

  // Set Selected Coordinates Area
  on(fromDashboard.setSelectedCoordinatesArea, (state, payload) => ({
    ...state,
    selectedCoordinatesArea: payload.area,
  })),

  // Set Selected Coordinates Area
  on(fromDashboard.clearAddFieldPanelData, (state) => ({
    ...state,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),

  // Update Dashboard Content
  on(fromDashboard.updateDashboardContent, (state) => ({
    ...state,
  })),

  // Set Selected Field
  on(fromDashboard.setSelectedField, (state, payload) => ({
    ...state,
    selectedField: payload.field,
  })),

  // Set Selected Satellite Image URL
  on(fromDashboard.setSelectedSatelliteImageURL, (state, payload) => ({
    ...state,
    selectedSatelliteImageURL: payload.satelliteImageURL,
  })),

  // Set Sensor Data Locations
  on(fromDashboard.setSensorDataLocations, (state, payload) => ({
    ...state,
    sensorDataLocations: payload.sensorDataLocations,
  })),
  // Select Sensor Data Location
  on(fromDashboard.selectSensorDataLocation, (state, payload) => ({
    ...state,
    selectedSensorDataLocation: payload.sensorDataLocation,
  })),

  // Set Device Locations
  on(fromDashboard.setDeviceLocations, (state, payload) => ({
    ...state,
    deviceLocations: payload.deviceLocations,
  })),
  // Select Sensor Data Location
  on(fromDashboard.selectDeviceLocation, (state, payload) => ({
    ...state,
    selectedDeviceLocation: payload.deviceLocation,
  })),

  // Set State of Field deisgnation drawing on map
  on(fromDashboard.selectDrawingOnMapFieldDesignation, (state) => ({
    ...state,
    drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.DESIGNATING,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),
  on(fromDashboard.unselectDrawingOnMapFieldDesignation, (state) => ({
    ...state,
    drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.UNSELECTED,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),
  on(fromDashboard.finishDrawingOnMapFieldDesignation, (state) => ({
    ...state,
    drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.DESIGNATED,
  })),
  on(fromDashboard.nameFieldDrawingOnMapFieldDesignation, (state) => ({
    ...state,
    drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.NAMING_FIELD,
  })),
  on(fromDashboard.saveFieldDrawingOnMapFieldDesignation, (state) => ({
    ...state,
    drawingOnMapFieldDesignationState: DrawingOnMapFieldDesignationState.UNSELECTED,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),

  // Set State of Field deisgnation with GPS
  on(fromDashboard.selectGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.INIT,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),
  on(fromDashboard.unselectGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.UNSELECTED,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),
  on(fromDashboard.startGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.DESIGNATING,
  })),
  on(fromDashboard.finishGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.DESIGNATED,
  })),
  on(fromDashboard.nameFieldGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.NAMING_FIELD,
  })),
  on(fromDashboard.saveGPSFieldDesignation, (state) => ({
    ...state,
    gpsFieldDesignationState: GPSFieldDesignationState.INIT,
    selectedCoordinates: [],
    selectedCoordinatesArea: 0,
  })),

  on(fromDashboard.sensorDataOverviewClicked, (state) => ({
    ...state,
    selectedSensorDataTab: SensorDataTab.OVERVIEW,
  })),
  on(fromDashboard.sensorDataDetailsClicked, (state) => ({
    ...state,
    selectedSensorDataTab: SensorDataTab.DETAILS,
  })),

  on(fromDashboard.setSelectedWateringDevice, (state, payload) => ({
    ...state,
    selectedWateringDevice: payload.wateringDevice,
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return dashboardReducer(state, action);
}
