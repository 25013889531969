import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dfarmDateToLocalISO } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { SensorDataResolutionEnum, SoilMoistureViewModel, SoilTemperatureViewModel } from '../models/sensor-data.model';

@Injectable({
  providedIn: 'root',
})
export class NodeDataService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Get Node Data
  getNodeSoilTemperature(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/node/soil-temperature/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilTemperatureViewModel>(url).pipe(
      map((dto): SoilTemperatureViewModel => {
        const gatewaySoilTemperatureViewModel: SoilTemperatureViewModel = {
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewaySoilTemperatureViewModel;
      })
    );
  }

  getNodeSoilMoisture(locationId: string, intervalStart: Date, intervalEnd: Date, resolution: SensorDataResolutionEnum): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/node/soil-moisture/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilMoistureViewModel>(url).pipe(
      map((dto): SoilMoistureViewModel => {
        const gatewaySoilMoistureViewModel: SoilMoistureViewModel = {
          raw: {
            time: dto.raw.time.map((time) => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map((time) => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map((time) => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewaySoilMoistureViewModel;
      })
    );
  }

  getNodeSoilConductivity(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)
    let params = new HttpParams();

    params = params.append('from', startDate);
    params = params.append('to', endDate);
    params = params.append('resolution', resolution);

    const url = `${this.baseUrl}sensor-data/node/soil-conductivity/${locationId}`;

    return this.http.get<any>(url, { params });
  }
}
