import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InvoiceViewModel } from '../../models/invoice.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dfarm-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class InvoiceDialogComponent {
  formControls: Record<keyof InvoiceViewModel, FormControl> = {
    name: new FormControl('', [Validators.required]),
    taxNumber: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
  };
  editProfileFormGroup = new FormGroup(this.formControls);

  isEditing = false;

  constructor(
    public dialogRef: MatDialogRef<InvoiceDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  save() {
    if (this.editProfileFormGroup.valid) {
      // TODO: Bekötni
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
